import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"

import Layout from "../components/layout"

class About extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const specialClass = 'simple-page'

    return (
      <Layout location={this.props.location} title={siteTitle} specialClass={specialClass}>
        <SEO
          title='About'
          description='About us'
        />
        <section className="hidden"></section>
        <section>
          <div className="page-section container">
            <div className="page-content">
              <h1> Who we are and why we built Invizi </h1>
              <p>
                We are a team of 2 engineers who deeply believe in blockchain’s ability to transform personal finance. It is our mission to empower people to manage their digital assets easily and efficiently without compromising their privacy.
                We want to usher in a world without data breaches, predatory ad models, commoditization of user’s data and abuse of privacy.
              </p>
              <br/>
              <p>
                Hence Invizi is 100% open source, locally hosted and free to use. We have many more exciting and unique features in the pipeline to serve our community better.
                To stay true to our mission of financial privacy as a fundamental right we are self funded and are completely invested in the product with our time and resources.
              </p>
              <br/>
              <p>
                Private, secure and open source, Invizi protects you.
              </p>

            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default About

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
